.tipsClass{
    display: flex;
    align-items: baseline;
    margin-left: -28px;
}


.icontips{
    margin-right: 12px;
}
.button{
    margin-top: 24px;
    margin-bottom: 21px;
    width: 328px;
    height: 48px;
    margin-left: 17px;
    border-radius: 14px;
}
.cardClass{
    max-width: 332px;
    margin-left:14px ;
     padding:20px;
    margin-top: 12px;
}
.percentClass{
    width: 328px;
    height: 148px;
    margin-bottom: -53px;
}
.amountRow{
    /*height: 64px;*/
    margin-bottom: -32px;;
    margin-top: -37px;
}
.tipsText{
    margin-top: 19px;
}
.amountClass{
margin-left:-28px;
}
.tipsClass{
    margin-top: -31px;
}
.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}
.tipButtons{
    margin-left: -47px;
    text-align: center;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    display: block;
    width: 70px;
    height: 56px;
    gap: 8px;
    margin-top: -12px;
}


