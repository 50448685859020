@import '../../assets/styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.homepage {
    & * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    // margin: auto;
    // width: 100%;
    // max-width: 400px;
    padding: 16px;
    height: 100vh;
    background-color: $backgroundColor;
    display: flex;
    flex-direction: column;
    align-items: center;

    .homepage-title {
        font-size: 16px;
        font-weight: 500;
        color: $titleColor;
        margin-bottom: 8px;
        margin-top: 20px;
    }

    .homepage-subtitle {
        font-size: 20px;
        font-weight: 600;
        color: $subtitleColor;
        margin-bottom: 50px;
        font-family: "Great Vibes", cursive;
        font-weight: 600;
        font-style: normal;
    }

    .homepage-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 330px;
        gap: 12px;

        .homepage-button {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 20px 16px;
            border-radius: 14px;
            background-color: #fff;

            .button-icon {
                width: 24px;
                height: 24px;
                margin-right: 6px;
            }

            .button-text {
                color: $titleColor;
                font-size: 16px;
                flex-grow: 1;
            }

            .arrow {
                width: 20px;
            }
        }
    }
}