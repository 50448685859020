
@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Bold Outline Trial.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Bold Outline Trial Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Black Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Bold Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial DemiBold.ttf') format('truetype');
    font-weight: 600; /* или другое значение, если DemiBold не поддерживается */
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial DemiBold Italic.ttf') format('truetype');
    font-weight: 600; /* или другое значение, если DemiBold не поддерживается */
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial ExtraBold Italic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial ExtraLight Italic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Hairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Hairline Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Light Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Medium Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Thin Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves ';
    src: url('TT Hoves Pro Trial Variable.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
