.App{
    background-color: #F4F7FA;

;
}
.App-header{
    display: flex;
    font-family: "TT Hoves",sans-serif;
    color: #22292E;
    font-style: normal;
    font-size: 20px;
    font-weight: 600;
    Line-height:28px;
    margin-left:16px;
    /*margin-top:14px;*/
    align-items: center;
    gap: 91px;
}
.logoClass{
    width: 40px;
    height: 40px;
    margin-top: 23px;
    background-color: black;
    border-radius: 50%;
}

.headText{
    font-size: 20px;
    font-weight: 600;
    margin-left: -19px;
    margin-top: 29px;
    margin-bottom: 1px;
}